var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('div',{staticClass:"all_header_box"},[_c('div',{staticClass:"sider_left"},[_c('span',{staticClass:"title"},[_c('router-link',{staticClass:"crumbs_item",attrs:{"tag":"a","to":"/admin/PromotionManage/couponManage"}},[_vm._v("优惠券管理 /")]),_c('span',{staticClass:"crumbs_item crumbs_last"},[_vm._v(" 领取记录")])],1)])]),_c('div',{staticClass:"title-box"},[(this.userType != 2)?_c('span',{staticClass:"right"},[_vm._v("优惠券名称："+_vm._s(_vm.name))]):_vm._e(),_c('span',[_vm._v("优惠券使用时间："+_vm._s(_vm.userTime))])]),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":"key","columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      showTotal: function (res) { return ("共" + _vm.total + "条"); },
      showQuickJumper: true,
      showSizeChanger: true,
      pageSize: _vm.pageSize,
      pageSizeOptions: ['10', '20', '30', '50', '100'],
    }},scopedSlots:_vm._u([{key:"state",fn:function(item){return [_vm._v(" "+_vm._s(item==1?'待使用':item==2?'已使用':'已失效')+" ")]}},{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + index + 1)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/PromotionManage/couponManage"
            >优惠券管理 /</router-link
          >
          <span class="crumbs_item crumbs_last"> 领取记录</span>
        </span>
      </div>
    </div>
    <div class="title-box">
      <span v-if="this.userType != 2" class="right">优惠券名称：{{name}}</span>
      <!-- <span v-if="this.userType == 2" class="right">兑换券名称：兑换VB进阶课程</span> -->
      <span>优惠券使用时间：{{userTime}}</span>
    </div>
    <a-table
      class="table-template"
      rowKey="key"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSize: pageSize,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
    >
      <template slot="state" slot-scope="item">
        {{item==1?'待使用':item==2?'已使用':'已失效'}}
      </template>
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
    </a-table>

    <!-- <div class="button">
      <a-button
        type="primary"
        class="btn"
      ><router-link
            class="crumbs_item"
            tag="a"
            to="/admin/PromotionManage/couponManage"
          >返回</router-link></a-button>
    </div> -->
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const reducecolumns = [
  // {
  //   title: "序号",
  //   align: "center",
  //   dataIndex: "index",
  //   scopedSlots: {
  //     customRender: "index",
  //   },
  // },
  {
    title: "优惠券编码",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "用户账号",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "领取时间",
    align: "center",
    dataIndex: "receiverTime",
  },
  {
    title: "使用状态",
    align: "center",
    dataIndex: "state",
    scopedSlots: {
      customRender: "state",
    },
  },
  {
    title: "使用时间",
    align: "center",
    dataIndex: "usersTime",
  },
];
// const changecolumns = [
//   {
//     title: "优惠券编码",
//     align: "center",
//     dataIndex: "code",
//   },
//   {
//     title: "用户账号",
//     align: "center",
//     dataIndex: "userName",
//   },
//   {
//     title: "使用状态",
//     align: "center",
//     dataIndex: "state",
//   },
//    {
//     title: "发放人",
//     align: "center",
//     dataIndex: "author",
//   }
// ];
const discountcolumns = [
  {
    title: "折扣券编码",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "用户账号",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "领取时间",
    align: "center",
    dataIndex: "receiverTime",
  },
  {
    title: "使用状态",
    align: "center",
    dataIndex: "state",
    scopedSlots: {
      customRender: "state",
    },
  },
  {
    title: "使用时间",
    align: "center",
    dataIndex: "usersTime",
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      name:'',
      userTime:'',
      reducecolumns,
      // changecolumns,
      discountcolumns,
      columns: " ",
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
    };
  }, // 事件处理器
  methods: {
    getList() {
      this.$ajax({
        url: "/hxclass-management/coupon-manage/manage/draw/" + this.id,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // this.total = res.data.total / 1;
          this.tableData = res.data.drawList;
          this.name =  res.data.name;
          this.userTime =  res.data.userTime;
          this.total = res.data.drawList.length;

        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.userType = this.$route.query.userType;
    this.id = this.$route.query.id;
    if (this.userType == 1) {
      this.columns = reducecolumns;
    }
    // else if (this.userType == 2) {
    //   this.columns = changecolumns;
    // }
    else if (this.userType == 3) {
      this.columns = discountcolumns;
    }
    this.getList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  a {
    color: #333333;
  }
  .title-box {
    margin-top: 24px;
    font-size: 16px;
    color: #000;
    .right {
      margin-right: 24px;
    }
  }
}
</style>
